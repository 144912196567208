export const versions = {
  version: "1.0.183",
  revision: "3a472fa62",
  branch: "HEAD",
  gitInfo: {
    dirty: true,
    raw: "v1.0.183-4734-g3a472fa62-dirty",
    hash: "g3a472fa62",
    distance: 4734,
    tag: "v1.0.183",
    semver: {
      options: { loose: false, includePrerelease: false },
      loose: false,
      raw: "v1.0.183",
      major: 1,
      minor: 0,
      patch: 183,
      prerelease: [],
      build: [],
      version: "1.0.183"
    },
    suffix: "4734-g3a472fa62-dirty",
    semverString: "1.0.183+4734.g3a472fa62.dirty"
  }
};
